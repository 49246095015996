@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.container {
  width: 100%;
  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
  .container-about {
    width: 45%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 180px;
    margin-top: 50px;
    @media (max-width: 768px) {
      width: 90%;
      margin-left: 0px;
      padding-left: 15px;
      margin-top: 20px;
    }
    h2 {
      font-family: "Montserrat";
      font-weight: 400;
      color: #dada06;
      margin-bottom: -20px;
      margin-top: 50px;
      font-size: 22px;
      line-height: 20px;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
    h1 {
      font-family: "Poppins";
      font-weight: 800;
      font-size: 55px;
      line-height: 1em;
      color: #1b252f;
      margin-bottom: -10px;
      @media (max-width: 768px) {
        font-size: 48px;
      }
    }
    p {
      font-family: "Montserrat";
      font-weight: 400;
      line-height: 1.8em;
      letter-spacing: 0.1px;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
  .container-buttonabout {
    padding-top: 15px;
    padding-bottom: 10px;
    .whatsapp {
      display: inline-block;
      padding: 15px 100px;
      cursor: pointer;
      border-radius: 2px;
      border: solid;
      border-width: 1px;
      background-color: #f4a40400; /* WhatsApp green */
      color: rgb(0, 0, 0);
      font-size: 16px;
      &:hover {
        background-color: #1b252f;
        color: white;
        padding: 15px 110px;
        transition-duration: 0.3s;
      }
    }
  }
}
