@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100..900;1,100..900&display=swap");

.container-newlaunch {
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-top: 55px;
  }
  .judul-new {
    font-family: "advent Pro";
    font-size: 76px;
    font-weight: 500;
    color: #292929;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      text-align: center;
      font-size: 50px;
    }
  }
  .img-new {
    display: flex;
    align-items: center;
    justify-content: center;
    .gambar {
      width: 70%;
      border-radius: 10px;
      box-shadow: 0 0 10px 0;
      @media (max-width: 768px) {
        width: 90%;
      }
    }
  }
  .detail-new {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    .judul-detail {
      color: #292929;
      font-size: 36px;
      font-family: "Poppins";
      font-weight: 600;
    }
    .desk-detail {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .lokasi-detail {
        font-size: 18px;
        margin-bottom: 15px;
      }
      .list-detail {
        padding-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 5px;
          padding-right: 20px;
          @media (max-width: 768px) {
            padding-right: 20px;
            font-size: 12px;
          }
        }
      }
    }
    .button-nl {
      padding-top: 15px;
      padding-bottom: 10px;
      .whatsapp {
        display: inline-block;
        padding: 15px 100px;
        cursor: pointer;
        border-radius: 2px;
        border: solid;
        border-width: 1px;
        background-color: #f4a40400; /* WhatsApp green */
        color: rgb(0, 0, 0);
        font-size: 16px;
        &:hover {
          background-color: #1b252f;
          color: white;
          // padding: 15px 110px;
          transition-duration: 0.3s;
        }
      }
    }
  }
}
