@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100..900;1,100..900&display=swap");

.container-rumah {
  width: 100%;
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .judul-rumah {
    font-family: "advent Pro";
    font-size: 76px;
    font-weight: 500;
    color: #292929;
    margin-bottom: 20px;
  }
  .produk {
    width: 100%;
  }
}
