.container-lokasi {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;

  .maps {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    .img-map {
      width: 80%;
      background-image: url(../../media/Lokasi.jpeg);
      background-size: cover;
      background-position: center;
      height: 70vh;
      @media (max-width: 768px) {
        background-image: url(../../media/lokasi-mobile.jpeg);
        height: 60vh;
      }
    }
  }

  .judul-lokasi {
    font-family: "advent Pro";
    font-size: 76px;
    font-weight: 600;
    color: #292929;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      text-align: center;
      font-size: 60px;
    }
  }
  .carousel-lokasi {
    width: 80%;
    @media (max-width: 768px) {
      width: 95%;
      margin-bottom: 20px;
      margin-left: 0px;
    }
    .kartugambar2 {
      width: 70%;
    }
    .slick-slider {
      width: 100%;
      .slick-next::before,
      .slick-prev::before {
        font-family: "slick";
        font-size: 30px;
      }
      .slick-list {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
      .slick-track {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
      }

      .slick-next {
        right: 25px;
      }
      .slick-prev {
        z-index: 1;
        left: 25px;
      }
    }
  }
}
