@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.container-header {
  width: 100%;
  background-color: #989898;
  .tittle {
    color: rgb(255, 255, 255);
    font-family: "Poppins";
    font-weight: 600;
    font-size: 18px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .background-container {
    background-image: url("../../media/banner.png"); /* Replace with your desktop image URL */
    background-size: cover;
    background-position: center;
    height: 90vh;
    @media (max-width: 768px) {
      /* Adjust breakpoint as needed */
      background-image: url("../../media/Header\ SCG\ \(320\ x\ 480\ px\)\ \(11\).png"); /* Replace with your mobile image URL */
      height: 70vh;
    }
  }
}
